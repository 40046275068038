import { createContext, useState } from "react";

const SideBarContext = createContext();

const SideBarContextProvider = ({ children }) => {
  const [nav, setNav] = useState("");
  const [videoSearchValue, setVideoSearchValue] = useState("");
  const [nuSearchValue, setNuSearchValue] = useState("");
  const [exeSearchValue, setExeSearchValue] = useState("");
  const [packageSearchValue, setPackageSearchValue] = useState("");
  const [speVideoSearchValue, setSpeVideoSearchValue] = useState("");

  const [trainerSearchValue, setTrainerSearchValue] = useState("");
  const [exeVideoSearchValue, setExeVideoSearchValue] = useState("");

  return (
    <SideBarContext.Provider
      value={{
        nav,
        setNav,
        videoSearchValue,
        setVideoSearchValue,
        packageSearchValue,
        setPackageSearchValue,
        speVideoSearchValue,
        setSpeVideoSearchValue,
        nuSearchValue,
        setNuSearchValue,
        exeSearchValue,
        setExeSearchValue,
        trainerSearchValue,
        setTrainerSearchValue,
        exeVideoSearchValue,
        setExeVideoSearchValue,
      }}
    >
      {children}
    </SideBarContext.Provider>
  );
};

export default SideBarContext;
export { SideBarContextProvider };

import { gql } from "@apollo/client";
//get all muscle type
export const ALL_MUSCLES_TYPES = gql`
  query aa($search: String!, $limit: Int!, $offset: Int!) {
    muscle_types(
      limit: $limit
      offset: $offset
      order_by: { updated_at: desc }
      where: { name: { _ilike: $search } }
    ) {
      id
      name
      image_url
      created_at
    }
    muscle_types_aggregate(where: { name: { _ilike: $search } }) {
      aggregate {
        count
      }
    }
  }
`;

//get muscle type pk
export const MUSCLE_TYPES_PK = gql`
  query aa($id: uuid!) {
    muscle_types_by_pk(id: $id) {
      id
      name
      image_url
    }
  }
`;

//create muscle type
export const ADD_MUSCLE_TYPE = gql`
  mutation MyMutation($image_url: String!, $name: String!) {
    insert_muscle_types_one(object: { image_url: $image_url, name: $name }) {
      id
      image_url
      name
    }
  }
`;

//update muslce type
export const UPDATE_MUSCLE_TYPE = gql`
  mutation MyMutation($image_url: String!, $name: String!, $id: uuid!) {
    update_muscle_types_by_pk(
      pk_columns: { id: $id }
      _set: { image_url: $image_url, name: $name }
    ) {
      image_url
      name
    }
  }
`;

//delete muslce type
export const DELETE_MUSCLE_TYPE = gql`
  mutation MyMutation($id: uuid!) {
    delete_muscle_types_by_pk(id: $id) {
      id
    }
  }
`;

//update position
export const POSITION = gql`
  mutation MyMutation($id: uuid!, $updated_at: timestamptz!) {
    update_muscle_types_by_pk(
      pk_columns: { id: $id }
      _set: { updated_at: $updated_at }
    ) {
      updated_at
      id
    }
  }
`;

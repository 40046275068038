import { gql } from "@apollo/client";
//all subscrption packages
export const ALL_SUB_PACKAGES = gql`
  query aa($search: String!, $limit: Int!, $offset: Int!) {
    subscription_packages(
      where: { title: { _ilike: $search } }
      order_by: { updated_at: desc }
      limit: $limit
      offset: $offset
    ) {
      id
      title
      description_en
      description_mm
      created_at
      updated_at
      subscription_level {
        subscription_type
        id
      }
    }
    subscription_packages_aggregate {
      aggregate {
        count
      }
    }
  }
`;

//get id
export const SUB_PACKAGE_BY_PK = gql`
  query aa($id: uuid!) {
    subscription_packages_by_pk(id: $id) {
      id
      title
      description_en
      description_mm
      created_at
      updated_at
      subscription_level {
        subscription_type
        id
      }
    }
  }
`;

//add sub package
export const ADD_SUB_PACKAGE = gql`
  mutation aa($addServicePackage: subscription_packages_insert_input!) {
    insert_subscription_packages_one(object: $addServicePackage) {
      id
    }
  }
`;

//delete sub package
export const DELETE_SUB_PACKAGE = gql`
  mutation aa($id: uuid!) {
    delete_subscription_packages_by_pk(id: $id) {
      id
    }
  }
`;
// user subscription level
export const ALL_SUB_LEVEL = gql`
  query aa {
    user_subscription_level {
      id
      subscription_type
    }
  }
`;

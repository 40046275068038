import React, {
  useState,
  lazy,
  Suspense,
  useEffect,
  createContext,
} from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { Alert } from "@mui/material";

import Header from "../layout/Header";
import SideBar from "../layout/SideBar";
import CreateVideo from "../components/video/CreateVideo";
import UpdateVideo from "../components/video/UpdateVideo";
import CreateRoutine from "./routines/CreatedRoutines";
import Routine from "./routines/Routine";
import CreateExeRoutine from "./exeRoutine/CreateExeRoutine";
import ExeRoutine from "./exeRoutine/ExeRoutine";
import MuscleTypes from "./muslceTypes/MuscleTypes";
import CreateMuscleType from "./muslceTypes/CreateMuscleType";
import MuscleType from "./muslceTypes/MuscleType";
import SubscriptionPakcages from "./subscriptionPakcages/SubscriptionPakcages";
import SubscriptionPakcage from "./subscriptionPakcages/SubscriptionPackage";
import SubscriptionPakcageVariant from "./subscriptionPakcagesVariant/SubscriptionPackageVariant";
import BankingAccs from "./bankingAccount/BankingAccs";
import BankingAcc from "./bankingAccount/BankingAcc";

//const Dashboard = lazy(() => import("./Dashboard"));

const Users = lazy(() => import("./users/index"));
const User = lazy(() => import("./users/User"));
const Video = lazy(() => import("./Video/index"));
const VideoID = lazy(() => import("./Video/VideoID"));
const Plans = lazy(() => import("./plans/Plans"));
const Plan = lazy(() => import("./plans/Plan"));
const NuRoutine = lazy(() => import("./nutritionRoutine/index"));
const SpecialExeRoutine = lazy(() => import("./specialExeRoutine/index"));
const NuRoutineId = lazy(() => import("./nutritionRoutine/nutrition"));
const SpecialNuRoutine = lazy(() => import("./specialNuRoutine/index"));
const SpecialNuRoutineId = lazy(() =>
  import("./specialNuRoutine/specialNuRoutineID")
);

const Blogs = lazy(() => import("./blogs/index"));
const Blog = lazy(() => import("./blogs/Blog"));

const SubTypes = lazy(() => import("./subType/SubTypes"));
const SubType = lazy(() => import("./subType/SubType"));

const SpecialTrainers = lazy(() => import("./SpecialTrainer/SpecialTrainers"));
const SpecialTrainer = lazy(() => import("./SpecialTrainer/SpecialTrainer"));

const SpecialPackages = lazy(() => import("./SpecialPackage/SpecialPackages"));
const SpecialPackage = lazy(() => import("./SpecialPackage/SpecialPackage"));

const SpecialVideos = lazy(() => import("./SpecialVideo/SpecialVideos"));
const SpecialVideo = lazy(() => import("./SpecialVideo/SpecialVideo"));
const Index = lazy(() => import("./SubscriptionHistory/Index"));
const SpecialHistory = lazy(() =>
  import("./SpecialPurchaseHistory/SpecialHistory")
);
const ChildSubTypes = lazy(() => import("./ChildsubType/ChildSubTypes"));
const ChildSubType = lazy(() => import("./ChildsubType/ChildSubType"));
const CreateChildSubType = lazy(() =>
  import("../components/childSubType/CreateChildSubType")
);
const UpdateChildSubType = lazy(() =>
  import("../components/childSubType/UpdateChildSubType")
);
const Ads = lazy(() => import("./Ads/index"));
const Transactions = lazy(() => import("./paymentTransaction/Transactions"));
const Transaction = lazy(() => import("./paymentTransaction/Transaction"));

const drawerWidth = 290;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const Admin = () => {
  const [open, setOpen] = useState(true);
  const [showAlert, setShowAlert] = useState({ message: "", isError: false });
  const navigate = useNavigate();
  const AuthContext = createContext();
  const [auth, setAuth] = useState(null);

  const handleDrawer = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const homeAlert = (message, isError = false) => {
    setShowAlert({ message: message, isError: isError });
    setTimeout(() => {
      setShowAlert({ message: "", isError: false });
    }, 3000);
  };

  useEffect(() => {
    const loggedUser = window.localStorage.getItem("loggedUser");
    if (loggedUser) {
      const parsedLoggedUser = JSON.parse(loggedUser);
      setAuth(parsedLoggedUser);
    } else {
      navigate("/login");
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          //bgcolor: "#F8F7FC",
          //bgcolor: "#1e1e1e",
          background: "#F7F7F7",
          minHeight: "100vh",
        }}
      >
        <CssBaseline />
        <Header open={open} handleDrawerOpen={handleDrawer} />
        <SideBar handleDrawerClose={handleDrawerClose} open={open} />
        <Main open={open}>
          <DrawerHeader />
          <Suspense fallback={<div>Loading...</div>}>
            <AuthContext.Provider value={auth}>
              <Routes>
                <Route path="/user" element={<Users />} />
                <Route path="/user/:id" element={<User />} />

                <Route path="/video" element={<Video />} />
                <Route path="/video/:id" element={<VideoID />} />
                <Route path="/video/create" element={<CreateVideo />} />
                <Route path="/video/update/:id" element={<UpdateVideo />} />

                <Route path="/plan" element={<Plans />} />
                <Route path="/plan/:id" element={<Plan />} />
                <Route path="/plan/:id/:id" element={<Routine />} />
                <Route path="/plan/:id/:id/:id" element={<ExeRoutine />} />
                <Route
                  path="/plan/:id/createRoutine"
                  element={<CreateRoutine />}
                />
                <Route
                  path="plan/:id/:id/createExeRoutine"
                  element={<CreateExeRoutine />}
                />

                <Route path="/nuroutine" element={<NuRoutine />} />
                <Route path="/nuroutine/:id" element={<NuRoutineId />} />

                <Route path="/blog" element={<Blogs />} />
                <Route path="/blog/:id" element={<Blog />} />

                <Route path="/sub_package" element={<SubscriptionPakcages />} />
                <Route
                  path="/sub_package/:id"
                  element={<SubscriptionPakcage />}
                />
                <Route
                  path="/sub_package_variant/:id"
                  element={<SubscriptionPakcageVariant />}
                />
                {/* banking acc */}
                <Route path="/banking_acc" element={<BankingAccs />} />
                <Route path="/banking_acc/:id" element={<BankingAcc />} />
                {/* muscleType */}
                <Route path="/muscleType" element={<MuscleTypes />} />
                <Route
                  path="/createMuscleType"
                  element={<CreateMuscleType />}
                />
                <Route path="/muscleType/:id" element={<MuscleType />} />

                <Route path="/transaction" element={<Transactions />} />
                <Route path="/transaction/:id" element={<Transaction />} />
                <Route path="/ads" element={<Ads />} />
                <Route
                  path="/spe_exe_routine"
                  element={<SpecialExeRoutine />}
                />
                <Route path="/spe_nu_routine" element={<SpecialNuRoutine />} />
                <Route
                  path="/spe_nu_routine/:id"
                  element={<SpecialNuRoutineId />}
                />
                <Route path="/subHistory" element={<Index />} />
                <Route path="/specialHistory" element={<SpecialHistory />} />

                {/* special Trainer */}
                <Route path="/special_trainer" element={<SpecialTrainers />} />
                <Route
                  path="/special_trainer/:id"
                  element={<SpecialTrainer />}
                />
                {/* special package */}
                <Route path="/special_package" element={<SpecialPackages />} />
                <Route
                  path="/special_package/:id"
                  element={<SpecialPackage />}
                />
                {/* special video */}
                <Route path="/special_video" element={<SpecialVideos />} />
                <Route path="/special_video/:id" element={<SpecialVideo />} />

                {/* child subType */}
                <Route path="/childSubType" element={<ChildSubTypes />} />
                <Route path="/childSubType/:id" element={<ChildSubType />} />
                <Route
                  path="/createChildSubType/:id"
                  element={<CreateChildSubType />}
                />
                <Route
                  path="/updateChildSubType/:id"
                  element={<UpdateChildSubType />}
                />
              </Routes>
            </AuthContext.Provider>
          </Suspense>
        </Main>
        {showAlert.message && !showAlert.isError && (
          <Alert
            sx={{ position: "fixed", bottom: "1em", right: "1em" }}
            severity="success"
          >
            {showAlert.message}
          </Alert>
        )}
        {showAlert.message && showAlert.isError && (
          <Alert
            sx={{ position: "fixed", bottom: "1em", right: "1em" }}
            severity="warning"
          >
            {showAlert.message}
          </Alert>
        )}
      </Box>
    </>
  );
};

export default Admin;

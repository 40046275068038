import { gql } from "@apollo/client";
export const ALL_ROUTINES = gql`
  query MyQuery(
    $limit: Int!
    $offset: Int!
    $search: String!
    $fk_plan_id: uuid!
  ) {
    routines(
      limit: $limit
      offset: $offset
      order_by: { updated_at: desc }
      where: {
        _and: {
          routine_name: { _ilike: $search }
          fk_plan_id: { _eq: $fk_plan_id }
        }
      }
    ) {
      fk_plan_id
      id
      image_url
      plan {
        plan_name
        id
      }
      routine_name
      created_at
    }
    routines_aggregate(
      where: {
        _and: { routine_name: { _ilike: $search } }
        fk_plan_id: { _eq: $fk_plan_id }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

//get routine by pk
export const ROUTINE_BY_PK = gql`
  query aa($id: uuid!) {
    routines_by_pk(id: $id) {
      id
      created_at
      image_url
      routine_name
      plan {
        plan_name
        id
      }
    }
  }
`;
//create routine
export const ADD_ROUTINE = gql`
  mutation MyMutation(
    $fk_plan_id: uuid!
    $image_url: String!
    $routine_name: String!
  ) {
    insert_routines_one(
      object: {
        fk_plan_id: $fk_plan_id
        image_url: $image_url
        routine_name: $routine_name
      }
    ) {
      id
      fk_plan_id
    }
  }
`;

//update routine
export const UPDATE_ROUTINE = gql`
  mutation UPDATE_ROUTINE_BY_PK(
    $id: uuid!
    $fk_plan_id: uuid!
    $image_url: String!
    $routine_name: String!
  ) {
    update_routines_by_pk(
      pk_columns: { id: $id }
      _set: {
        fk_plan_id: $fk_plan_id
        image_url: $image_url
        routine_name: $routine_name
      }
    ) {
      routine_name
      created_at
      id
    }
  }
`;

//delete routine
export const DELETE_ROUTINE = gql`
  mutation aa($id: uuid!) {
    delete_routines_by_pk(id: $id) {
      id
    }
  }
`;

//put to top
export const POSITIION = gql`
  mutation MyMutation($id: uuid!, $updated_at: timestamptz!) {
    update_routines_by_pk(
      pk_columns: { id: $id }
      _set: { updated_at: $updated_at }
    ) {
      id
    }
  }
`;

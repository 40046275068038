import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import DirectionsIcon from "@mui/icons-material/Directions";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import SideBarContext from "../../context/SideBarContext";
import {
  Breadcrumbs,
  Button,
  TableBody,
  TablePagination,
  TableHead,
  TableRow,
  TableContainer,
  styled,
  Typography,
  Table,
  TableCell,
  Box,
  Modal,
  InputBase,
  Paper,
  Avatar,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import NorthIcon from "@mui/icons-material/North";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { ALL_ROUTINES } from "../../gql/routines";
import { format } from "date-fns";
import { ALL_EXE_ROUTINE, POSITIION } from "../../gql/exeRoutine";
import CreatePlan from "../../components/plan/CreatePlan";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100vw",
  height: "100vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
};
const styleR = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  width: 300,
  backgroundColor: "#cecece",
};

const ExeRoutines = ({ planId, routineId }) => {
  const navigate = useNavigate();

  const { setNav } = useContext(SideBarContext);
  const { exeSearchValue, setExeSearchValue } = useContext(SideBarContext);
  //for search button
  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");

  //for routine modal
  const [removeRoutine, setRemoveRoutine] = useState("");
  const [updateRoutine, setUpdateRoutine] = useState("");
  const [removeOpen, setRemoveOpen] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);

  //for alert message
  const [showAlert, setShowAlert] = useState("");

  // for pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);

  const [routine, setRoutine] = useState({});
  const [status, setStatus] = useState(false);

  // const [loadRoutine, resultRoutines] = useLazyQuery(ALL_PLANS);
  const { data: resultRoutines } = useQuery(ALL_EXE_ROUTINE, {
    variables: {
      limit: rowsPerPage,
      offset: offset,
      search: `%${search}%`,
      fk_routine_id: routineId,
      pending: status,
    },
    fetchPolicy: "network-only",
  });

  // ---------------------****------------------------

  //for search button
  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(searchValue);
    setExeSearchValue(searchValue);
    if (searchValue === "") {
      // loadRoutine();
    }
  };

  //------------ ADD routine -------------
  const routineAlert = (message, isError = false) => {
    setShowAlert({ message: "", isError: false });
    setTimeout(() => {
      setShowAlert({ message: "", isError: false });
    }, 3000);
  };

  const [updatePosition] = useMutation(POSITIION, {
    onError: (error) => {
      console.log("error: ", error);
    },
    onCompleted: (res) => {
      alert("Exercise Routine have been put to the top.");
    },
    refetchQueries: [ALL_EXE_ROUTINE],
  });

  const handleCreateOpen = () => {
    setCreateOpen(true);
  };

  const handleCreateClose = () => {
    setCreateOpen(false);
  };

  // for pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOffset(rowsPerPage * newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  if (!resultRoutines) {
    return <em>Loading .....</em>;
  }

  return (
    <>
      <Box sx={{ mx: "0.5rem", mt: "1rem" }}>
        <form onSubmit={handleSearch}>
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: "auto",
            }}
          >
            {/* Search Box */}
            <InputBase
              id="search-by-title"
              sx={{ ml: 1, flex: 1, fontSize: "15px" }}
              placeholder="Search By Exe Routine Name"
              type="search"
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
            />
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton
              color="warning"
              id="search-by-title"
              sx={{ p: "10px" }}
              aria-label="directions"
              value={search}
              type="submit"
              onClick={handleSearch}
            >
              <DirectionsIcon />
            </IconButton>
          </Paper>
        </form>
      </Box>

      <div>
        <Box
          sx={{
            display: "flex",
            flexFlow: "wrap row",
            "& > :not(style)": {
              m: 1,
              width: "100%",
              minHeight: "25vh",
            },
            mt: "1rem",
          }}
        >
          <TableContainer
            sx={{
              Width: "10px",
              overflowY: "hidden",
              // border: "1px groove rgba(0,0,0,0.2)",
            }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell style={{ minWidth: 100 }}>
                    Exe Routine name
                  </TableCell>
                  <TableCell style={{ minWidth: 100 }}>Routine Name</TableCell>
                  <TableCell style={{ minWidth: 100 }}>Video ID</TableCell>
                  <TableCell style={{ minWidth: 100 }}>Exercise Type</TableCell>
                  <TableCell style={{ minWidth: 100 }}>Created_at</TableCell>
                  <TableCell style={{ minWidth: 100 }}>Actions</TableCell>
                </TableRow>
              </TableHead>

              {resultRoutines?.exercise_routine.length === 0 ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={12} style={{ textAlign: "center" }}>
                      <Typography variant="h6" color="error">
                        No Exe Routine Data
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {Array.isArray(resultRoutines?.exercise_routine) &&
                    resultRoutines?.exercise_routine.map((row, index) => (
                      <StyledTableRow hover key={index}>
                        <TableCell>{index + 1}</TableCell>
                        {/* <TableCell>
                          <Avatar
                            alt="routine image"
                            src={row?.image_url}
                            sx={{ width: 52, height: 52 }}
                          />
                        </TableCell> */}

                        <TableCell>{row?.exercise_routine_name}</TableCell>
                        <TableCell>{row?.routine?.routine_name}</TableCell>
                        <TableCell>{row?.video_list?.video_id}</TableCell>
                        <TableCell>
                          {row?.video_list?.exercise_type?.exercise_type_name}
                        </TableCell>
                        <TableCell>
                          {format(
                            new Date(row?.created_at),
                            "yyyy-MM-dd hh:mm:ss a"
                          )}
                        </TableCell>

                        <TableCell>
                          {/* <Button
                            // onClick={() => handleRemoveOpen(row)}
                            size="small"
                            color="error"
                          >
                            Remove
                          </Button>

                          <Button
                            //onClick={() => handleUpdateOpen(row)}
                            size="small"
                            color="primary"
                          >
                            Edit
                          </Button> */}

                          <Button
                            component={Link}
                            to={`/plan/${planId}/${routineId}/${row?.id}`}
                            size="small"
                            color="warning"
                          >
                            Details
                          </Button>
                          <Button
                            sx={{ fontWeight: "bold", ml: "1rem" }}
                            color="primary"
                            onClick={() =>
                              updatePosition({
                                variables: {
                                  id: row?.id,
                                  updated_at: new Date().toISOString(),
                                },
                              })
                            }
                          >
                            <NorthIcon />
                          </Button>
                        </TableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <TablePagination
            sx={{ color: "black" }}
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={resultRoutines?.exercise_routine_aggregate?.aggregate?.count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </div>

      <div>
        <Modal
          keepMounted
          open={createOpen}
          onClose={handleCreateClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box style={style}>
            <CreatePlan
              routineAlert={routineAlert}
              handleClose={handleCreateClose}
            />
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default ExeRoutines;

import { gql } from "@apollo/client";
//get all sub package variant
export const ALL_SUB_PACKAGE_VARIANT = gql`
  query aa($limit: Int!, $offset: Int!) {
    subscription_package_variants(
      order_by: { price: asc }
      limit: $limit
      offset: $offset
    ) {
      id
      title_en
      title_mm
      description_en
      description_mm

      durationInDays
      price
      original_price
      disabled
      iap_product_id
      created_at
    }
    subscription_package_variants_aggregate {
      aggregate {
        count
      }
    }
  }
`;

//get by pk
export const SUB_PACKAGE_VARIANT_BY_PK = gql`
  query aa($id: uuid!) {
    subscription_package_variants_by_pk(id: $id) {
      title_en
      id
      title_mm
      description_en
      description_mm
      created_at

      durationInDays
      iap_product_id
      subscription_package_id
      disabled
      price
      original_price
      disable_promotion
      discount_percentage
    }
  }
`;

//add sub package variant
export const ADD_SUB_PACKAGE_VARIANT = gql`
  mutation aa(
    $addSubPackageVariant: subscription_package_variants_insert_input!
  ) {
    insert_subscription_package_variants_one(object: $addSubPackageVariant) {
      id
    }
  }
`;

//edit sub package variant
export const EDIT_SUB_PACKAGE_VARIANT = gql`
  mutation aa(
    $editSubPackageVariant: subscription_package_variants_set_input!
    $id: uuid!
  ) {
    update_subscription_package_variants_by_pk(
      pk_columns: { id: $id }
      _set: $editSubPackageVariant
    ) {
      id
    }
  }
`;

//delete sub package variant
export const DELETE_SUB_PACKAGE_VARIANT = gql`
  mutation aa($id: uuid!) {
    delete_subscription_package_variants_by_pk(id: $id) {
      id
    }
  }
`;

//update promotion
export const UPDATE_PROMOTION = gql`
  mutation MyMutation($id: uuid!, $disabled: Boolean!) {
    update_subscription_package_variants_by_pk(
      pk_columns: { id: $id }
      _set: { disabled: $disabled }
    ) {
      id
      disable_promotion
    }
  }
`;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Button,
  Modal,
  TablePagination,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
  InputBase,
  Divider,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";

import { useLazyQuery, useMutation } from "@apollo/client";
import { BLOGS, UPDATE_POSITION } from "../../gql/blog";
import CreateSubscriptionPackage from "../../components/subscripitonPackage/CreateSubscriptionPackage";
import DirectionsIcon from "@mui/icons-material/Directions";
import { ALL_SUB_PACKAGES } from "../../gql/subscriptionPakcage";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  height: "100vh",
  overflowY: "scroll",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
};
const SubscriptionPakcages = ({ homeAlert }) => {
  const navigate = useNavigate();

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [offset, setOffset] = useState(0);
  const [subPackages, setSubPackages] = useState(null);

  const [createOpen, setCreateOpen] = useState(false);

  //for search button
  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const [loadSubPakcages, result] = useLazyQuery(ALL_SUB_PACKAGES);

  const [updatePosition] = useMutation(UPDATE_POSITION, {
    onError: (error) => {
      console.log("error: ", error);
    },
    onCompleted: (res) => {
      homeAlert(`Subscription have been put to the top.`);
    },
    refetchQueries: [ALL_SUB_PACKAGES],
  });

  useEffect(() => {
    loadSubPakcages({
      variables: {
        limit: rowsPerPage,
        offset: offset,
        search: `%${search}%`,
      },
      fetchPolicy: "network-only",
    });
  }, [loadSubPakcages, offset, search, rowsPerPage]);

  useEffect(() => {
    if (result.data) {
      setSubPackages(result?.data?.subscription_packages);

      setCount(
        Number(result.data?.subscription_packages_aggregate?.aggregate.count)
      );
    }
  }, [result]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOffset(rowsPerPage * newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCreateOpen = (scrollType) => {
    setCreateOpen(true);
  };

  //for search button
  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(searchValue);
    if (searchValue === "") {
      loadSubPakcages();
    }
  };
  useEffect(() => {
    loadSubPakcages({
      variables: {
        limit: rowsPerPage,
        offset: offset,
        search: `%${search}%`,
      },
      fetchPolicy: "network-only",
    });
  }, [loadSubPakcages, search]);

  if (!subPackages) {
    return (
      <div>
        <em>Loading...</em>
      </div>
    );
  }

  return (
    <div>
      <div className="align">
        {/* dashboard */}
        <div>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/user" className="dashboard">
              Dashboard
            </Link>
            <span>Subscription Packages</span>
          </Breadcrumbs>
        </div>
        {/* search */}
        <div>
          <form onSubmit={handleSearch}>
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "auto",
              }}
            >
              {/* Search Box */}

              <InputBase
                id="search-by-title"
                sx={{ ml: 1, flex: 1, fontSize: "15px" }}
                placeholder="Search By Title"
                type="search"
                onChange={(e) => setSearchValue(e.target.value)}
              />

              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                color="warning"
                id="search-by-title"
                sx={{ p: "10px" }}
                aria-label="directions"
                value={search}
                type="submit"
                onClick={handleSearch}
              >
                <DirectionsIcon />
              </IconButton>
            </Paper>
          </form>
        </div>
      </div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          {/* <Button
            variant="contained"
            sx={{
              px: 3,
              py: 1,
            }}
            color="secondary"
            onClick={() => handleCreateOpen("paper")}
          >
            Add
          </Button> */}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexFlow: "wrap row",
          "& > :not(style)": {
            m: 1,
            width: "100%",
            minHeight: "25vh",
          },
        }}
      >
        <TableContainer sx={{ overflowY: "hidden" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Description Eng</TableCell>
                <TableCell>Description MM</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>

            {subPackages.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={12} style={{ textAlign: "center" }}>
                    <Typography variant="h6" color="error">
                      No Packages Data
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {subPackages?.map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row.title?.substring(0, 20)}....</TableCell>
                      {console.log("row ", row)}
                      <TableCell>
                        {row?.description_en
                          ?.map((m, index) => m?.substring(0, 15))
                          .join(" , ")}
                      </TableCell>
                      <TableCell>
                        {row?.description_mm
                          ?.map((m, index) => m?.substring(0, 15))
                          .join(" , ")}
                      </TableCell>

                      <TableCell>{row.created_at?.substring(0, 10)}</TableCell>

                      <TableCell>
                        <Button
                          component={Link}
                          size="small"
                          color="warning"
                          // onClick={() => detailBlog(row)}
                          to={`/sub_package/${row.id}`}
                        >
                          Detail
                        </Button>
                        {/* <Button
                          variant="contained"
                          sx={{ fontWeight: "bold", ml: "1rem" }}
                          color="primary"
                          onClick={() =>
                            updatePosition({
                              variables: {
                                id: row.id,
                                updateAt: new Date().toISOString(),
                              },
                            })
                          }
                        >
                          Top
                        </Button> */}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <div>
        <Modal
          keepMounted
          open={createOpen}
          //onClose={handleCreateClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box style={style}>
            <CreateSubscriptionPackage />
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default SubscriptionPakcages;

import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  FormControl,
  TextField,
  Typography,
  Alert,
  InputLabel,
  FormHelperText,
  Button,
  CircularProgress,
  Card,
  CardContent,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { ADD_SUB_PACKAGE, ALL_SUB_LEVEL } from "../../gql/subscriptionPakcage";

const CreateSubscriptionPackage = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({ message: "", isError: false });
  const [values, setValues] = useState({});
  const [descriptionEN, setDescriptionEN] = useState([""]);
  const [descriptionMM, setDescriptionMM] = useState([""]);
  const [errors, setErrors] = useState({});
  const { data: subLevels } = useQuery(ALL_SUB_LEVEL);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangeDescEN = (index) => (event) => {
    const newDescriptions = [...descriptionEN];
    newDescriptions[index] = event.target.value;
    setDescriptionEN(newDescriptions);
  };

  const handleChangeDescMM = (index) => (event) => {
    const newDescriptions = [...descriptionMM];
    newDescriptions[index] = event.target.value;
    setDescriptionMM(newDescriptions);
  };

  const addDescriptionFieldEN = () => {
    setDescriptionEN([...descriptionEN, ""]);
  };

  const removeDescriptionFieldEN = (index) => {
    const newDescriptions = descriptionEN.filter((_, i) => i !== index);
    setDescriptionEN(newDescriptions);
  };

  const addDescriptionFieldMM = () => {
    setDescriptionMM([...descriptionMM, ""]);
  };

  const removeDescriptionFieldMM = (index) => {
    const newDescriptions = descriptionMM.filter((_, i) => i !== index);
    setDescriptionMM(newDescriptions);
  };
  console.log("values ", values);

  const [createSubPackage] = useMutation(ADD_SUB_PACKAGE, {
    onError: (error) => {
      console.log("error : ", error);
      alert("Sub Package Upload Error", error);
      setLoading(false);
    },
    // onCompleted: () => {
    //   setValues({});
    //   setErrors({});
    //   setDescriptionEN([""]);
    //   setDescriptionMM([""]);
    //   setLoading(false);
    //   setShowAlert({
    //     message: "Subscription Package have been created.",
    //     isError: false,
    //   });
    //   setTimeout(() => {
    //     setShowAlert({ message: "", isError: false });
    //   }, 1000);
    // },
  });
  console.log("description en ", descriptionEN);
  console.log("description mm ", descriptionMM);
  const handleCreate = async () => {
    setLoading(true);
    setErrors({});
    let isErrorExit = false;
    let errorObject = {};
    if (!values.title) {
      errorObject.title = "Title field is required.";
      isErrorExit = true;
    }
    // if (descriptionEN.some((desc) => !desc)) {
    //   errorObject.description_en = "All English descriptions are required.";
    //   isErrorExit = true;
    // }
    // if (descriptionMM.some((desc) => !desc)) {
    //   errorObject.description_mm = "All Myanmar descriptions are required.";
    //   isErrorExit = true;
    // }
    if (isErrorExit) {
      setErrors({ ...errorObject });
      setLoading(false);
      return;
    }
    console.log("---------------------- 1");
    try {
      await createSubPackage({
        variables: {
          addServicePackage: {
            title: values.title,
            description_en: ["test", "test"],
            description_mm: ["test", "test"],
            subscription_level_id: values.subscription_level_id,
          },
        },
      });
      // navigate(-1);
    } catch (error) {
      console.log("error : ", error);
    }
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          color: "black",
          bgcolor: "#cecece",
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
          py: 2,
        }}
      >
        <Typography variant="h5" component="h2" color="black" sx={{ mx: 4 }}>
          Create Subscription package
        </Typography>
        <Button
          onClick={() => navigate("/sub_package")}
          variant="contained"
          color="error"
          sx={{ mx: 4 }}
        >
          Close
        </Button>
      </Box>

      <Card sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
        <CardContent>
          <Box>
            {/* Title */}
            <TextField
              id="title"
              label="Title"
              value={values.title || ""}
              onChange={handleChange("title")}
              error={!!errors.title}
              helperText={errors.title}
            />
          </Box>
          <br />
          {/* Subscription Level */}
          <Box>
            <FormControl sx={{ width: "15rem" }}>
              <InputLabel id="main_type">Subscription Level</InputLabel>
              <Select
                labelId="main_type"
                value={values.subscription_level_id}
                label="Subscription Level"
                onChange={handleChange("subscription_level_id")}
              >
                <MenuItem value="" disabled>
                  Value
                </MenuItem>
                {subLevels?.user_subscription_level?.map((level, index) => (
                  <MenuItem key={index} value={level.id}>
                    {level.subscription_type}
                  </MenuItem>
                ))}
              </Select>
              {errors.subscription_level_id && (
                <FormHelperText error>
                  {errors.subscription_level_id}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
          <br />
          {/* Description EN */}
          <Box>
            {descriptionEN.map((desc, index) => (
              <Box
                key={index}
                sx={{ display: "flex", alignItems: "center", mb: 2 }}
              >
                <TextField
                  fullWidth
                  label="Description Eng"
                  value={desc}
                  onChange={handleChangeDescEN(index)}
                  error={!!errors.description_en}
                  helperText={errors.description_en}
                />
                <IconButton
                  onClick={() => removeDescriptionFieldEN(index)}
                  sx={{ ml: 1 }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            ))}
            <Button variant="contained" onClick={addDescriptionFieldEN}>
              Add +
            </Button>
          </Box>
          <br />
          {/* Description MM */}
          <Box>
            {descriptionMM.map((desc, index) => (
              <Box
                key={index}
                sx={{ display: "flex", alignItems: "center", mb: 2 }}
              >
                <TextField
                  fullWidth
                  label="Description MM"
                  value={desc}
                  onChange={handleChangeDescMM(index)}
                  error={!!errors.description_mm}
                  helperText={errors.description_mm}
                />
                <IconButton
                  onClick={() => removeDescriptionFieldMM(index)}
                  sx={{ ml: 1 }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            ))}
            <Button variant="contained" onClick={addDescriptionFieldMM}>
              Add +
            </Button>
          </Box>
        </CardContent>
        <Box className="btn_end">
          <Button
            variant="contained"
            size="large"
            sx={{ height: 50, width: 100 }}
            disabled={loading}
            onClick={handleCreate}
          >
            {loading ? <CircularProgress color="warning" /> : "Create"}
          </Button>
        </Box>
      </Card>
      {showAlert.message && !showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="success"
        >
          {showAlert.message}
        </Alert>
      )}
      {showAlert.message && showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="error"
        >
          {showAlert.message}
        </Alert>
      )}
    </div>
  );
};

export default CreateSubscriptionPackage;

import { useLazyQuery, useQuery } from "@apollo/client";
import { useMutation } from "@apollo/client";

import RichTextEditor from "react-rte";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormHelperText,
  CardMedia,
  Alert,
  CircularProgress,
} from "@mui/material";
import { Box } from "@mui/system";

import { useState, useEffect } from "react";
import { PLAN_PK } from "../../gql/plan";

import { DELETE_IMAGE, GET_IMAGE_UPLOAD_URL } from "../../gql/misc";
import imageService from "../../services/image";
import { useRef } from "react";
import { EXE_TARGET, EXP_LEVEL, SUBSCRIPTION_TYPE } from "../../gql/mixed";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTINE_BY_PK, UPDATE_ROUTINE } from "../../gql/routines";

const fileTypes = [
  "image/apng",
  "image/bmp",
  "image/gif",
  "image/jpeg",
  "image/pjpeg",
  "image/png",
  "image/svg+xml",
  "image/tiff",
  "image/webp",
  "image/x-icon",
];
const imageType = "image/*";
const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};
const UpdateRoutine = ({ handleClose, routineAlert, routine }) => {
  const navigate = useNavigate();
  const [values, setValues] = useState({});
  const { id } = useParams();
  const [errors, setErrors] = useState({});

  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({ message: "", isError: false });

  const [selectedImage, setSelectedImage] = useState();
  const [imagePreview, setImagePreview] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageFileUrl, setImageFileUrl] = useState(null);
  const [isImageChange, setIsImageChange] = useState(false);
  const [oldImageName, setOldImageName] = useState(null);

  const { data: plans, error } = useQuery(PLAN_PK, {
    variables: { id: routine?.plan?.id },
  });

  const fileInputRef = useRef(null);

  //assign data from db

  useEffect(() => {
    if (routine) {
      const routines = routine;
      setValues(routines);

      setImagePreview(routines?.image_url);
      let image = routines?.image_url;
      setOldImageName(
        image?.substring(image?.lastIndexOf("/") + 1, image?.lenght)
      );
    }
  }, [routine]);

  // for image
  const [getImageUrl] = useMutation(GET_IMAGE_UPLOAD_URL, {
    onError: (error) => {
      console.log("imge errors", error);
      setShowAlert({ message: "Error on server", isError: true });
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 1000);
    },
    onCompleted: (result) => {
      setLoading(false);
      setIsImageChange(true);
    },
  });

  const imageChange = async (e) => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];
      setSelectedImage(img);
      setValues({ ...values, image_url: URL.createObjectURL(img) });
      if (!fileTypes.includes(img.type)) {
        setErrors({
          ...errors,
          image_url: "Please select image. (PNG, JPG, JPEG, GIF, ...)",
        });
        return;
      }
      if (img.size > 10485760) {
        setErrors({
          ...errors,
          image_url: "Image file size must be smaller than 10MB.",
        });
        return;
      }
    }
  };
  const [deleteImage] = useMutation(DELETE_IMAGE, {
    onError: (error) => {
      console.log("error : ", error);
      setLoading(false);
    },
  });
  //for input box
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    delete errors[prop];
    setErrors(errors);
  };

  //for create routine
  const [UpdateRoutine] = useMutation(UPDATE_ROUTINE, {
    onError: (error) => {
      console.log("error : ", error);
      setLoading(false);
    },
    onCompleted: (result) => {
      setValues({});

      setErrors({});

      setLoading(false);
      alert("Routine has been updated");
      handleClose();
    },
    refetchQueries: [ROUTINE_BY_PK],
  });

  const handleUpdate = async () => {
    setLoading(true);
    setErrors({});
    let isErrorExit = false;
    let errorObject = {};

    // if (!values.plan_name) {
    //   errorObject.plan_name = "Plan name is required";
    //   isErrorExit = true;
    // }
    // if (!values.image_url) {
    //   errorObject.image_url = "Image field is required.";
    //   isErrorExit = true;
    // }
    // if (!values.fk_user_subscription_level_id) {
    //   errorObject.fk_user_subscription_level_id =
    //     "Subscription level is required.";
    //   isErrorExit = true;
    // }
    // if (!values.fk_exercise_target_id) {
    //   errorObject.fk_exercise_target_id = "Exercise Target is required.";
    //   isErrorExit = true;
    // }
    // if (!values.fk_experience_level_id) {
    //   errorObject.fk_experience_level_id = "Experience Level is required.";
    //   isErrorExit = true;
    // }

    // if (!values.description) {
    //   errorObject.description = "Description is required";
    //   isErrorExit = true;
    // }
    // if (!values.description_mm) {
    //   errorObject.description_mm = "Description MM is required";
    //   isErrorExit = true;
    // }

    // if (!values.routine_days_in_a_week) {
    //   errorObject.routine_days_in_a_week = "routine days is required";
    //   isErrorExit = true;
    // }

    if (isErrorExit) {
      setErrors(errorObject);
      console.log("error object", errorObject);
      setLoading(false);
      return;
    }

    try {
      const updatedValues = { ...values };
      if (isImageChange) {
        const res = await getImageUrl({
          variables: { contentType: "image/*" },
        });
        await imageService.uploadImage(
          res.data.getImageUploadUrl.imageUploadUrl,
          selectedImage
        );
        updatedValues.image_url = `https://axra.sgp1.digitaloceanspaces.com/PowerPlay/${res.data.getImageUploadUrl.imageName}`;
        await deleteImage({ variables: { image_name: oldImageName } });
      } else if (selectedImage) {
        const res = await getImageUrl({
          variables: { contentType: "image/*" },
        });
        await imageService.uploadImage(
          res.data.getImageUploadUrl.imageUploadUrl,
          selectedImage
        );
        updatedValues.image_url = `https://axra.sgp1.digitaloceanspaces.com/PowerPlay/${res.data.getImageUploadUrl.imageName}`;
      }

      await UpdateRoutine({
        variables: {
          ...updatedValues,
          fk_plan_id: plans?.plans_by_pk?.id,
        },
      });
    } catch (error) {
      console.log("error : ", error);
    }
  };

  //to clear data after clicking close btn
  const handleClosClearData = () => {
    setValues({});
    setErrors({});
    handleClose();
  };
  if (!plans) {
    return "no plan data";
  }

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          color: "black",
          bgcolor: "#cecece",
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
          py: 2,
        }}
      >
        <Typography variant="h5" component="h2" color="black" sx={{ mx: 4 }}>
          Update Routine
        </Typography>
        <Button
          onClick={() => navigate(-1)}
          variant="contained"
          color="error"
          sx={{ mx: 4 }}
        >
          Close
        </Button>
      </Box>

      <Card sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
        <CardContent className="grid--2--cols">
          {values?.image_url ? (
            <Box className="grid-img">
              <CardMedia
                sx={{
                  flex: 1,
                  bgcolor: "#cecece",
                  objectFit: "contain",
                  mt: 4,
                  boxShadow: 5,
                  borderRadius: 2,
                }}
                component="img"
                height="300"
                image={values?.image_url}
                alt="exercise image"
                className="grid_img"
              />
            </Box>
          ) : (
            <Box className="grid-img">
              <CardMedia
                sx={{
                  flex: 1,
                  bgcolor: "#cecece",
                  objectFit: "contain",
                  mt: 4,
                  boxShadow: 5,
                  borderRadius: 2,
                }}
                component="img"
                height="300"
                className="grid_img"
              />
            </Box>
          )}

          <div className="grid-item grid--2--cols">
            {/* thumbnail_image_url */}
            <TextField
              id="image_url"
              label="image_url"
              type="file"
              accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
              InputLabelProps={{ shrink: true }}
              //value={values.thumbnail_image_url}
              onChange={imageChange}
              error={errors.image_url ? true : false}
              helperText={errors.image_url}
              inputRef={fileInputRef}
            />
            {/* routine name */}
            <TextField
              id="routine_name"
              label="Routine Name"
              value={values.routine_name ? values.routine_name : ""}
              onChange={handleChange("routine_name")}
              error={errors.routine_name ? true : false}
              helperText={errors.routine_name}
            />
            {/* plan_name */}

            <TextField
              id="title"
              label="Plan Name"
              value={plans?.plans_by_pk?.plan_name}
              // onChange={handleChange("routine_name")}
              //error={errors.routine_name ? true : false}
              //helperText={errors.routine_name}
            />
          </div>
        </CardContent>
        <Box className="btn_end">
          <Button
            variant="contained"
            //color="warning"
            size="large"
            sx={{ height: 50, width: 100 }}
            disabled={loading}
            onClick={handleUpdate}
          >
            {loading ? <CircularProgress color="warning" /> : "Update"}
          </Button>
        </Box>
      </Card>
      {showAlert.message && !showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="success"
        >
          {showAlert.message}
        </Alert>
      )}
      {showAlert.message && showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="error"
        >
          {showAlert.message}
        </Alert>
      )}
    </div>
  );
};

export default UpdateRoutine;

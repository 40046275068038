import React, { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { BLOG, DELETE_BLOG } from "../../gql/blog";
import {
  Breadcrumbs,
  Typography,
  Box,
  Paper,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  ListItem,
  ListItemText,
  CardActions,
  Button,
  Modal,
  Alert,
} from "@mui/material";

import { format } from "date-fns";
import { DELETE_EXE_ROUTINE, EXE_ROUTINE_BY_PK } from "../../gql/exeRoutine";
import UpdateExeRoutine from "./UpdateExeRoutine";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100vw",
  height: "100vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
};
const styleR = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ExeRoutine = ({ homeAlert }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { data: result, error } = useQuery(EXE_ROUTINE_BY_PK, {
    variables: { id: id },
  });

  const [showAlert, setShowAlert] = useState({ message: "", isError: false });
  const [openU, setOpenU] = useState(false);
  const [openR, setOpenR] = useState(false);
  const [exeRoutine, setExeRoutine] = useState();
  const routineAlert = (message, isError = false) => {
    setShowAlert({ message: message, isError: isError });
    setTimeout(() => {
      setShowAlert({ message: "", isError: false });
    }, 3000);
  };

  const [deleteRoutine] = useMutation(DELETE_EXE_ROUTINE, {
    onError: (error) => {
      console.log(error);
      setShowAlert({ message: "Error on server", isError: true });
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 3000);
    },
    onCompleted: () => {
      alert("Exercise Routine has been removed");
      navigate(-1);
    },
  });

  const handleOpenU = (data) => {
    setOpenU(true);
    setExeRoutine(data);
  };
  const handleCloseU = () => setOpenU(false);
  const handleOpenR = () => setOpenR(true);
  const handleCloseR = () => setOpenR(false);

  const routine = result?.exercise_routine_by_pk;

  const handleDelete = () => {
    deleteRoutine({ variables: { id: routine.id } });
  };
  if (!result) {
    return (
      <div>
        <em>Loading...</em>
      </div>
    );
  }
  return (
    <div>
      <div role="presentation">
        <Breadcrumbs aria-label="breadcrumb">
          <Button onClick={() => navigate(-1)}>Back</Button>
        </Breadcrumbs>
      </div>
      <Typography variant="h6" component="h2" sx={{ my: 3, color: "black" }}>
        Exercise Routine Details
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexFlow: "wrap row",
          "& > :not(style)": {
            m: 1,
            width: "100%",
            minHeight: "25vh",
          },
        }}
      >
        <Paper elevation={3}>
          <Card>
            <CardHeader>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
              >
                Exercise Routine
              </Typography>
            </CardHeader>

            <CardContent sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                sx={{
                  flex: 2,
                  // mx: 1,
                  py: 1,
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <Box>
                  <ListItem>
                    <ListItemText primary="ID" secondary={routine?.id} />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Exercise Routine Name"
                      secondary={routine?.exercise_routine_name}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Routine Name"
                      secondary={routine?.routine?.routine_name}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Video ID"
                      secondary={routine?.video_list?.video_id}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Created At"
                      secondary={
                        routine?.created_at &&
                        format(
                          new Date(routine?.created_at),
                          "yyyy-MM-dd hh:mm:ss a"
                        )
                      }
                    />
                  </ListItem>
                </Box>
              </Box>
            </CardContent>

            <CardActions sx={{ justifyContent: "flex-end" }}>
              <Box>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => handleOpenU(routine)}
                >
                  Edit
                </Button>
                <Button size="small" color="error" onClick={handleOpenR}>
                  Remove
                </Button>
              </Box>
            </CardActions>
          </Card>
        </Paper>
      </Box>
      <Box sx={{ px: "0.5rem" }}>
        {/* <Button
          variant="contained"
          onClick={() =>
            navigate(`/plan/${id}/${routine?.id}/createExeRoutine`)
          }
        >
          Add ExeRoutine
        </Button> */}
      </Box>

      <Modal
        keepMounted
        open={openR}
        onClose={handleCloseR}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={styleR}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Confirmation
          </Typography>
          <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
            Are you sure want to remove?
          </Typography>
          <Box sx={{ textAlign: "right", mt: 2 }}>
            <Button color="secondary" onClick={handleCloseR}>
              Cancel
            </Button>
            <Button onClick={handleDelete}>Confirm</Button>
          </Box>
        </Box>
      </Modal>
      <div style={{ minHeight: "auto" }}>
        <Modal
          open={openU}
          onClose={handleCloseU}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ width: "100vw" }}
        >
          <Box sx={style}>
            <UpdateExeRoutine
              exeRoutine={exeRoutine}
              handleClose={handleCloseU}
              // routineAlert={routineAlert}
            />
          </Box>
        </Modal>
      </div>
      {showAlert.message && !showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="success"
        >
          {showAlert.message}
        </Alert>
      )}
      {showAlert.message && showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="warning"
        >
          {showAlert.message}
        </Alert>
      )}
    </div>
  );
};

export default ExeRoutine;

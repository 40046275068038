import { gql } from "@apollo/client";
//all banking acc
export const ALL_BANKING_ACC = gql`
  query aa($limit: Int!, $offset: Int!) {
    banking_accounts(
      order_by: { updated_at: desc }
      limit: $limit
      offset: $offset
    ) {
      id
      information
      information_sub
      information_point
      payment_service_name
      account_number
      payment_logo_url
      receiver_name
      created_at
      updated_at
      qr_image_url
    }
    banking_accounts_aggregate {
      aggregate {
        count
      }
    }
  }
`;

//get banking acc by pk
export const BANKING_ACC_BY_PK = gql`
  query aa($id: uuid!) {
    banking_accounts_by_pk(id: $id) {
      id
      receiver_name
      account_number
      payment_service_name
      information
      information_sub
      information_point
      created_at
      qr_image_url
    }
  }
`;

//add banking acc
export const ADD_BANKING_ACC = gql`
  mutation aa($addBankingAcc: banking_accounts_insert_input!) {
    insert_banking_accounts_one(object: $addBankingAcc) {
      id
    }
  }
`;

//edit banking acc
export const EDIT_BANKING_ACC = gql`
  mutation aa($id: uuid!, $editBankingAcc: banking_accounts_set_input!) {
    update_banking_accounts_by_pk(
      pk_columns: { id: $id }
      _set: $editBankingAcc
    ) {
      id
    }
  }
`;

//delete banking acc
export const DELETE_BANKGING_ACC = gql`
  mutation aa($id: uuid!) {
    delete_banking_accounts_by_pk(id: $id) {
      id
    }
  }
`;

//for unique payment service name
export const ALL_PAYMENT_SERVICE_NAMES = gql`
  query GetAllPaymentServiceNames {
    banking_accounts {
      payment_service_name
    }
  }
`;

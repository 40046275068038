import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Button,
  Modal,
  TablePagination,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
  InputBase,
  Divider,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import NorthIcon from "@mui/icons-material/North";
import { useLazyQuery, useMutation } from "@apollo/client";
import { BLOGS, UPDATE_POSITION } from "../../gql/blog";
import CreateBlog from "../../components/blogs/CreateBlog";
import DirectionsIcon from "@mui/icons-material/Directions";
import { ALL_MUSCLES_TYPES, POSITION } from "../../gql/muscleTypes";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100vw",
  height: "100vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
};
const MuscleTypes = ({ homeAlert }) => {
  const navigate = useNavigate();

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [offset, setOffset] = useState(0);
  const [blogs, setBlogs] = useState(null);

  const [createOpen, setCreateOpen] = useState(false);

  //for search button
  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const [loadMucleTypes, result] = useLazyQuery(ALL_MUSCLES_TYPES);

  const [updatePosition] = useMutation(POSITION, {
    onError: (error) => {
      console.log("error: ", error);
    },
    onCompleted: (res) => {
      // homeAlert(`Muscle Type have been put to the top.`);
      alert("Muscle Type have been put to the top.");
    },
    refetchQueries: [ALL_MUSCLES_TYPES],
  });

  useEffect(() => {
    loadMucleTypes({
      variables: {
        limit: rowsPerPage,
        offset: offset,
        search: `%${search}%`,
      },
      fetchPolicy: "network-only",
    });
  }, [loadMucleTypes, offset, search, rowsPerPage]);

  useEffect(() => {
    if (result.data) {
      setBlogs(result.data.muscle_types);

      setCount(Number(result.data?.muscle_types_aggregate.aggregate.count));
    }
  }, [result]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOffset(rowsPerPage * newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCreateOpen = (scrollType) => {
    setCreateOpen(true);
  };

  //for search button
  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(searchValue);
    if (searchValue === "") {
      loadMucleTypes();
    }
  };
  useEffect(() => {
    loadMucleTypes({
      variables: {
        limit: rowsPerPage,
        offset: offset,
        search: `%${search}%`,
      },
      fetchPolicy: "network-only",
    });
  }, [loadMucleTypes, search]);

  if (!blogs) {
    return (
      <div>
        <em>Loading...</em>
      </div>
    );
  }

  return (
    <div>
      <div className="align">
        {/* dashboard */}
        <div>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/user" className="dashboard">
              Dashboard
            </Link>
            <span>Muscle Type</span>
          </Breadcrumbs>
        </div>
        {/* search */}
        <div>
          <form onSubmit={handleSearch}>
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "auto",
              }}
            >
              {/* Search Box */}

              <InputBase
                id="search-by-title"
                sx={{ ml: 1, flex: 1, fontSize: "15px" }}
                placeholder="Search By Title"
                type="search"
                onChange={(e) => setSearchValue(e.target.value)}
              />

              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                color="warning"
                id="search-by-title"
                sx={{ p: "10px" }}
                aria-label="directions"
                value={search}
                type="submit"
                onClick={handleSearch}
              >
                <DirectionsIcon />
              </IconButton>
            </Paper>
          </form>
        </div>
      </div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <Button
            variant="contained"
            sx={{
              px: 3,
              py: 1,
            }}
            color="secondary"
            onClick={() => navigate("/createMuscleType")}
          >
            Add
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexFlow: "wrap row",
          "& > :not(style)": {
            m: 1,
            width: "100%",
            minHeight: "25vh",
          },
        }}
      >
        <TableContainer sx={{ overflowY: "hidden" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Image</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>

            {blogs.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={12} style={{ textAlign: "center" }}>
                    <Typography variant="h6" color="error">
                      No Muscle Types Data
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {blogs?.map((row, index) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row?.name}</TableCell>

                      <TableCell>
                        <Avatar
                          alt="blog"
                          src={row?.image_url}
                          sx={{ width: 56, height: 56 }}
                        >
                          B
                        </Avatar>
                      </TableCell>
                      <TableCell>{row?.created_at.substring(0, 10)}</TableCell>

                      <TableCell>
                        <Button
                          component={Link}
                          size="small"
                          color="warning"
                          // onClick={() => detailBlog(row)}
                          to={`/muscleType/${row?.id}`}
                        >
                          Detail
                        </Button>
                        <Button
                          sx={{ fontWeight: "bold", ml: "1rem" }}
                          color="primary"
                          onClick={() =>
                            updatePosition({
                              variables: {
                                id: row.id,
                                updated_at: new Date().toISOString(),
                              },
                            })
                          }
                        >
                          <NorthIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <div>
        <Modal
          keepMounted
          open={createOpen}
          //onClose={handleCreateClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box style={style}>
            <CreateBlog />
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default MuscleTypes;

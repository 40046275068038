import React, { useState, useEffect } from "react";
import { useMutation, useQuery, useParams } from "@apollo/client";
import imageService from "../../services/image";
import { GET_IMAGE_UPLOAD_URL, DELETE_IMAGE } from "../../gql/misc";
import { Box } from "@mui/system";
import RichTextEditor from "react-rte";
import {
  UPDATE_VIDEOS,
  DELETE_EXE_ROUTINE,
  CREATE_VIDEO_MUSCLE_TYPE_LIST,
  GET_VIDEO_MUSCLE_TYPES,
  DELETE_MUSCLE_TYPE_LIST,
  VIDEOBYID,
} from "../../gql/video";

import {
  Button,
  Typography,
  Card,
  CardMedia,
  CardContent,
  TextField,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  FormHelperText,
  CircularProgress,
  Alert,
  ListItemText,
  OutlinedInput,
  Checkbox,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CheckBox } from "@mui/icons-material";
import {
  EXE_TYPE,
  EXP_LEVEL,
  MUSCLE_TYPE,
  SUBSCRIPTION_TYPE,
} from "../../gql/mixed";
import { isArray } from "@apollo/client/cache/inmemory/helpers";

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};

const thumbnailfileTypes = [
  "image/apng",
  "image/bmp",
  "image/gif",
  "image/jpeg",
  "image/pjpeg",
  "image/png",
  "image/svg+xml",
  "image/tiff",
  "image/webp",
];
const videoType = "video/*";
const imageType = "image/*";
const videoFileTypes = ["video/mp4", "video/webm", "video/mkv"];
const UpdateVideo = ({ handleClose, video }) => {
  const [isImageChange, setIsImageChange] = useState(false);
  const [oldImageName, setOldImageName] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageFileUrl, setImageFileUrl] = useState(null);

  const [videoAFile, setVideoAFile] = useState(null);
  const [videoAFileUrl, setVideoAFileUrl] = useState(null);
  const [isVideoAChange, setIsVideoAChange] = useState(false);
  const [oldVideoAName, setOldVideoAName] = useState(null);
  const [videoAPreview, setVideoAPreview] = useState();

  const [videoBFile, setVideoBFile] = useState(null);
  const [videoBFileUrl, setVideoBFileUrl] = useState(null);
  const [isVideoBChange, setIsVideoBChange] = useState(false);
  const [oldVideoBName, setOldVideoBName] = useState(null);
  const [videoBPreview, setVideoBPreview] = useState();

  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({ message: "", isError: false });

  const [textValue, setTextValue] = useState(RichTextEditor.createEmptyValue());
  const [textValueMM, setTextValueMM] = useState(
    RichTextEditor.createEmptyValue()
  );

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});

  const [muscleType, setMuscleType] = useState([]);

  const { data: exeTypes } = useQuery(EXE_TYPE);
  const { data: expLevels } = useQuery(EXP_LEVEL);
  const { data: muslceTypess } = useQuery(MUSCLE_TYPE);
  const { data: subscriptionTypes } = useQuery(SUBSCRIPTION_TYPE);
  const { data: videoMuscleTypes } = useQuery(GET_VIDEO_MUSCLE_TYPES, {
    variables: {
      fk_video_list_id: video ? video?.id : "",
    },
  });

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: "80vh",
        width: 250,
      },
    },
  };

  useEffect(() => {
    setValues({
      promotion: video?.promotion,
      thumbnail_image_url: video?.thumbnail_image_url,
      video_package_name: video?.video_package_name,
      video_url_a: video?.video_url_a,
      video_url_b: video?.video_url_b,
      duration_in_minute: video?.duration_in_minute,
      description: video?.description,
      description_mm: video?.description_mm,
      fk_exercise_type_name: video?.exercise_type?.exercise_type_name,
      fk_user_subscription_level_id: video?.user_subscription_level?.id,
      fk_experience_level_id: video?.experience_level?.id,
    });

    setMuscleType(
      videoMuscleTypes && isArray(videoMuscleTypes?.video_lists_muscle_types)
        ? videoMuscleTypes?.video_lists_muscle_types?.map(
            (item) => item?.fk_muscle_type_id
          )
        : []
    );

    setImagePreview(video?.thumbnail_image_url);
    setVideoAPreview(video?.video_url_a);
    let videoLinkA = video?.video_url_a;
    setOldVideoAName(
      videoLinkA?.substring(
        videoLinkA?.lastIndexOf("/") + 1,
        videoLinkA?.lenght
      )
    );

    let videoLinkB = video?.video_url_b;
    setOldVideoAName(
      videoLinkB?.substring(
        videoLinkB?.lastIndexOf("/") + 1,
        videoLinkB?.lenght
      )
    );
    let imageName = video?.thumbnail_image_url;
    setOldImageName(
      imageName?.substring(imageName?.lastIndexOf("/") + 1, imageName?.lenght)
    );
    setVideoBPreview(video?.video_url_b);
    setTextValue(
      RichTextEditor.createValueFromString(video?.description, "html")
    );
    setTextValueMM(
      RichTextEditor.createValueFromString(video?.description_mm, "html")
    );
  }, [video]);

  const [createVideoSubType] = useMutation(CREATE_VIDEO_MUSCLE_TYPE_LIST, {
    onError: (err) => {
      console.log("Error ", err);
    },
    onCompleted: (res) => {},
  });

  const [deleteVideoMuscleTypeList] = useMutation(DELETE_MUSCLE_TYPE_LIST, {
    onError: (err) => {
      console.log("Error ", err);
    },
  });

  // ------------- for input box -----------
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [deleteImage] = useMutation(DELETE_IMAGE, {
    onError: (error) => {
      console.log("error : ", error);
      setLoading(false);
    },
    onCompleted: (data) => {},
  });

  // -------------------- for create and update --------------

  const [updateVideos] = useMutation(UPDATE_VIDEOS, {
    onError: (error) => {
      console.log("error : ", error);
      alert("Video Update Error");
      setLoading(false);
    },
    onCompleted: async (data) => {
      await deleteVideoMuscleTypeList({
        variables: { fk_video_list_id: data?.update_video_list_by_pk.id },
      });

      muscleType?.map(async (item) => {
        await createVideoSubType({
          variables: {
            fk_muscle_type_id: item,
            fk_video_list_id: data?.update_video_list_by_pk.id,
          },
        });
      });

      setValues({});
      setErrors({});
      setImageFile("");
      setImagePreview("");
      setVideoAFile("");
      setVideoBFile("");
      alert("New Video has been updated");
      setLoading(false);
      handleClose(false);
    },
    refetchQueries: [VIDEOBYID],
  });

  const handleChangesMuscleType = (event) => {
    const {
      target: { value },
    } = event;

    // setSubType(typeof value === "string" ? value.split(",") : value);
    setMuscleType((prevSubType) => {
      const newValue = typeof value === "string" ? value.split(",") : value;

      return newValue;
    });
  };
  const getSubtypeNameById = (uuid) => {
    const subtype = muslceTypess?.muscle_types.find(
      (subtype) => subtype.id === uuid
    );
    return subtype ? subtype.name : "";
  };
  const [getVideoAUrl] = useMutation(GET_IMAGE_UPLOAD_URL, {
    onError: (error) => {
      console.log("error : ", error);
    },
    onCompleted: (result) => {
      setVideoAFileUrl(result.getImageUploadUrl.imageUploadUrl);
      setIsVideoAChange(true);
      setValues({
        ...values,
        video_url_a: `https://axra.sgp1.digitaloceanspaces.com/PowerPlay/${result.getImageUploadUrl.imageName}`,
      });
    },
  });

  const [getVideoBUrl] = useMutation(GET_IMAGE_UPLOAD_URL, {
    onError: (error) => {
      console.log("error : ", error);
    },
    onCompleted: (result) => {
      setVideoBFileUrl(result.getImageUploadUrl.imageUploadUrl);
      setIsVideoBChange(true);
      setValues({
        ...values,
        video_url_b: `https://axra.sgp1.digitaloceanspaces.com/PowerPlay/${result.getImageUploadUrl.imageName}`,
      });
    },
  });

  const [getImageUrl] = useMutation(GET_IMAGE_UPLOAD_URL, {
    onError: (error) => {
      console.log("imge errors", error);
      setShowAlert({ message: "Error on server", isError: true });
      setTimeout(() => {
        setShowAlert({ message: "", isError: false });
      }, 1000);
    },
    onCompleted: (result) => {
      setImageFileUrl(result.getImageUploadUrl.imageUploadUrl);
      setIsImageChange(true);

      setValues({
        ...values,
        thumbnail_image_url: `https://axra.sgp1.digitaloceanspaces.com/PowerPlay/${result.getImageUploadUrl.imageName}`,
      });
    },
  });

  const imageChange = async (e) => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0];
      if (!thumbnailfileTypes.includes(img.type)) {
        setErrors({
          ...errors,
          thumbnail_image_url: "Please select image. (JPEG)",
        });
        return;
      }
      if (img.size > 10485760) {
        setErrors({
          ...errors,
          thumbnail_image_url: "Image file size must be smaller than 10MB.",
        });
        return;
      }
      setImageFile(img);
      setImagePreview(URL.createObjectURL(img));
      getImageUrl({ variables: { contentType: imageType } });
    }
  };

  const videoChangeA = async (e) => {
    if (e.target.files && e.target.files[0]) {
      let videofile = e.target.files[0];

      if (!videoFileTypes.includes(videofile.type)) {
        setErrors({
          ...errors,
          video_url_a: "Please select video. (mp4,webm,..)",
        });
        return;
      }

      setVideoAFile(videofile);
      setVideoAPreview(URL.createObjectURL(videofile));
      getVideoAUrl({ variables: { contentType: videoType } });
    }
  };

  const videoChangeB = async (e) => {
    if (e.target.files && e.target.files[0]) {
      let videofile = e.target.files[0];

      if (!videoFileTypes.includes(videofile.type)) {
        setErrors({
          ...errors,
          video_url_b: "Please select video. (mp4)",
        });
        return;
      }

      setVideoBFile(videofile);
      setVideoBPreview(URL.createObjectURL(videofile));
      getVideoBUrl({ variables: { contentType: videoType } });
    }
  };

  const handleUpdate = async () => {
    setLoading(true);
    setErrors({});

    try {
      if (isImageChange) {
        await imageService.uploadImage(imageFileUrl, imageFile);
        await deleteImage({ variables: { image_name: oldImageName } });
      }
      if (videoAFile && videoBFile) {
        if (isVideoAChange) {
          await imageService.uploadImage(videoAFileUrl, videoAFile);
          await deleteImage({ variables: { image_name: oldVideoAName } });
        }
        if (isVideoBChange) {
          await imageService.uploadImage(videoBFileUrl, videoBFile);
          await deleteImage({ variables: { image_name: oldVideoBName } });
        }
      } else {
        if (isVideoAChange || isVideoBChange) {
          await imageService.uploadImage(
            videoAFileUrl || videoBFileUrl,
            videoAFile || videoBFile
          );
          await deleteImage({
            variables: { image_name: oldVideoAName || oldVideoBName },
          });
        }
      }

      await updateVideos({
        variables: { ...values, id: video.id },
      });

      // navigate(-1);
    } catch (error) {
      console.log("error : ", error);
    }
  };

  // for Description
  const onChange = (value) => {
    setTextValue(value);
    setValues({ ...values, description: value.toString("html") });
  };

  // for Description MM
  const onChangeMM = (value) => {
    setTextValueMM(value);
    setValues({ ...values, description_mm: value.toString("html") });
  };

  if (!values) {
    return "no data";
  }
  if (!video) {
    return "no video";
  }

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          color: "black",
          // width: "94.5vw",
          bgcolor: "#cecece",
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
          py: 2,
        }}
      >
        <Typography variant="h5" component="h2" color="black" sx={{ mx: 4 }}>
          Update Video
        </Typography>
        <Button
          onClick={handleClose}
          variant="contained"
          color="error"
          sx={{ mx: 4 }}
        >
          Close
        </Button>
      </Box>

      <Card
        sx={{
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        {video && (
          <CardContent>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "5rem",
                marginBottom: "2rem",
              }}
            >
              {/* image */}
              <Box>
                <CardMedia
                  component="img"
                  height="300"
                  image={imagePreview}
                  className="grid_img"
                  sx={{
                    flex: 1,
                    // bgcolor: "#eee",
                    maxHeight: 300,
                    objectFit: "contain",
                    width: 300,
                    mt: 4,
                    // boxShadow: 3,
                    border: 0.1,
                    borderRadius: 2,
                  }}
                />
                <Typography
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    color: "blue",
                    fontSize: "15px",
                    marginTop: "5px",
                  }}
                >
                  Image Preview
                </Typography>
              </Box>

              <Box
                sx={{
                  flex: 1,
                  bgcolor: "#cecece",
                  maxHeight: 300,
                  objectFit: "contain",
                  maxWidth: 300,
                  mt: 4,
                  boxShadow: 5,
                  borderRadius: 2,
                  borderColor: "white",
                }}
              >
                {values.video_url_a && (
                  <video controls width="300" height="300">
                    <source src={videoAPreview} type="video/mp4" />
                  </video>
                )}
              </Box>

              <Box
                sx={{
                  flex: 1,
                  bgcolor: "#cecece",
                  maxHeight: 300,
                  objectFit: "contain",
                  maxWidth: 300,
                  mt: 4,
                  boxShadow: 5,
                  borderRadius: 2,
                  borderColor: "white",
                }}
              >
                <video controls width="300" height="300">
                  <source src={videoBPreview} type="video/mp4" />
                </video>
              </Box>
            </div>

            {/* list items */}
            <div
              className="grid--2--cols grid-item"
              style={{
                columnGap: "5rem",
                rowGap: "2rem",
                paddingLeft: "2rem",
                paddingRight: "2rem",
              }}
            >
              {/* video url a */}
              <TextField
                id="video_url_a"
                label="Upload Video A"
                type="file"
                InputLabelProps={{ shrink: true }}
                onChange={videoChangeA}
                error={errors.video_url_a ? true : false}
                helperText={errors.video_url_a}
                accept="video/webm,video/mp4"
              />
              {/* video_url_b */}
              <TextField
                id="video_url_b"
                label="Upload Video B"
                type="file"
                InputLabelProps={{ shrink: true }}
                onChange={videoChangeB}
                error={errors.video_url_b ? true : false}
                helperText={errors.video_url_b}
                accept="video/webm video/mp4"
              />
              {/* thumbnail_image_url */}
              <TextField
                id="thumbnail_image_url"
                label="image_url"
                type="file"
                accept="image/jpeg"
                InputLabelProps={{ shrink: true }}
                //value={values.thumbnail_image_url}
                onChange={imageChange}
                error={errors.thumbnail_image_url ? true : false}
                helperText={errors.thumbnail_image_url}
              />
              {/* video_package_name */}
              <TextField
                id="video_package_name"
                label="Video Package Name"
                value={values.video_package_name}
                onChange={handleChange("video_package_name")}
                error={errors.video_package_name ? true : false}
                helperText={errors.video_package_name}
              />
              {/* main_type */}

              {values?.fk_exercise_type_name ? (
                <FormControl>
                  <InputLabel id="main_type">Exercise Type</InputLabel>
                  <Select
                    labelId="main_type"
                    value={values.fk_exercise_type_name}
                    label="Main Type"
                    defaultValue=""
                    onChange={handleChange("fk_exercise_type_name")}
                    error={errors.fk_exercise_type_name ? true : false}
                  >
                    <MenuItem value="" disabled>
                      Value
                    </MenuItem>
                    {exeTypes?.exercise_types?.map((exe, index) => (
                      <MenuItem key={index} value={exe.exercise_type_name}>
                        {exe?.exercise_type_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.fk_exercise_type_name && (
                    <FormHelperText error>
                      {errors.fk_exercise_type_name}
                    </FormHelperText>
                  )}
                </FormControl>
              ) : (
                ""
              )}

              {/* package_type */}
              {values?.fk_user_subscription_level_id ? (
                <FormControl>
                  <InputLabel id="main_type">Subscription Level</InputLabel>
                  <Select
                    labelId="package_type"
                    value={values.fk_user_subscription_level_id}
                    defaultValue=""
                    label="Subscription Level"
                    onChange={handleChange("fk_user_subscription_level_id")}
                    error={errors.fk_user_subscription_level_id ? true : false}
                  >
                    <MenuItem value="" disabled>
                      Value
                    </MenuItem>
                    {Array.isArray(subscriptionTypes.user_subscription_level)
                      ? subscriptionTypes.user_subscription_level.map((pt) => (
                          <MenuItem key={pt.id} value={pt.id}>
                            {pt.subscription_type}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                  {errors.fk_user_subscription_level_id && (
                    <FormHelperText error>
                      {errors.fk_user_subscription_level_id}
                    </FormHelperText>
                  )}
                </FormControl>
              ) : (
                ""
              )}
              {/* experience */}
              {values?.fk_experience_level_id ? (
                <FormControl>
                  <InputLabel id="experience">Experience</InputLabel>
                  <Select
                    labelId="experience"
                    value={values.fk_experience_level_id}
                    label="Experience"
                    defaultValue=""
                    onChange={handleChange("fk_experience_level_id")}
                    error={errors.fk_experience_level_id ? true : false}
                  >
                    <MenuItem value="" disabled>
                      Value
                    </MenuItem>
                    {expLevels?.experience_level?.map((exe, index) => (
                      <MenuItem key={index} value={exe.id}>
                        {exe?.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.fk_experience_level_id && (
                    <FormHelperText error>
                      {errors.fk_experience_level_id}
                    </FormHelperText>
                  )}
                </FormControl>
              ) : (
                ""
              )}
              {/* duration */}
              <TextField
                type="number"
                id="duration_in_minute"
                label="duration_in_minute"
                value={values.duration_in_minute}
                onChange={handleChange("duration_in_minute")}
                error={errors.duration_in_minute ? true : false}
                helperText={errors.duration_in_minute}
              />

              {/* promotion */}
              <FormControl>
                <InputLabel id="promotion">Promotion</InputLabel>
                <Select
                  labelId="promotion"
                  value={values.promotion === true ? "Yes" : "No"}
                  label="Promotion"
                  defaultValue=""
                  onChange={handleChange("promotion")}
                  error={errors.promotion ? true : false}
                >
                  <MenuItem value="" disabled>
                    Value
                  </MenuItem>
                  <MenuItem value="true">Yes</MenuItem>
                  <MenuItem value="false">No</MenuItem>
                </Select>
                {errors.promotion && (
                  <FormHelperText error>{errors.promotion}</FormHelperText>
                )}
              </FormControl>
              {/* muscle  type */}
              <FormControl>
                <InputLabel id="demo-multiple-checkbox-label">
                  Muscle Type
                </InputLabel>

                <Select
                  label="Muscle Type"
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={muscleType}
                  onChange={handleChangesMuscleType}
                  input={<OutlinedInput label="Tag" />}
                  renderValue={(selected) =>
                    selected.map((uuid) => getSubtypeNameById(uuid)).join(", ")
                  }
                  MenuProps={MenuProps}
                >
                  {muslceTypess && Array.isArray(muslceTypess?.muscle_types)
                    ? muslceTypess?.muscle_types?.map((names) => (
                        <MenuItem key={names.id} value={names?.id}>
                          <Checkbox
                            // checked={muscleType?.indexOf(names.name) > -1}
                            checked={muscleType?.includes(names?.id)}
                          />
                          <ListItemText primary={names.name} />
                        </MenuItem>
                      ))
                    : ""}
                </Select>
              </FormControl>

              {/* description */}
              <Box>
                <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                  Description
                </InputLabel>
                <RichTextEditor
                  className="description-text"
                  onChange={onChange}
                  value={textValue}
                  toolbarConfig={toolbarConfig}
                />
                {errors.description && (
                  <FormHelperText error> {errors.description}</FormHelperText>
                )}
              </Box>

              {/* description MM*/}
              <Box>
                <InputLabel style={{ marginBottom: 10, fontWeight: "bold" }}>
                  Description MM
                </InputLabel>
                <RichTextEditor
                  className="description-text"
                  //onChange={handleChange("description")}
                  onChange={onChangeMM}
                  value={textValueMM}
                  toolbarConfig={toolbarConfig}
                />
                {errors.description_mm && (
                  <FormHelperText error>
                    {" "}
                    {errors.description_mm}
                  </FormHelperText>
                )}
              </Box>
            </div>
          </CardContent>
        )}

        <Box className="btn_end">
          <Button
            variant="contained"
            className="progress"
            size="large"
            sx={{ height: 50, width: 100 }}
            loading={loading}
            // disabled={loading}
            onClick={handleUpdate}
          >
            {loading ? <CircularProgress color="warning" /> : "Update"}
          </Button>
        </Box>
      </Card>
      {showAlert.message && !showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="success"
        >
          {showAlert.message}
        </Alert>
      )}
      {showAlert.message && showAlert.isError && (
        <Alert
          sx={{ position: "fixed", bottom: "1em", right: "1em" }}
          severity="error"
        >
          {showAlert.message}
        </Alert>
      )}
    </div>
  );
};
export default UpdateVideo;

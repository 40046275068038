import { gql } from "@apollo/client";
//all videos
export const ALL_VIDEOS = gql`
  query MyQuery(
    $limit: Int!
    $offset: Int!
    $videoQuery: video_list_bool_exp!
  ) {
    video_list(
      limit: $limit
      offset: $offset
      order_by: { updated_at: desc }

      where: $videoQuery
    ) {
      created_at
      description
      duration_in_minute
      pending
      fk_user_subscription_level_id
      id
      promotion
      video_id
      thumbnail_image_url
      updated_at
      video_package_name
      video_url_a
      video_url_b
      user_subscription_level {
        id
        subscription_type
      }
      experience_level {
        id
        name
      }
      video_lists_muscle_types {
        muscle_type {
          name
        }
      }
      exercise_type {
        exercise_type_name
        id
      }
      exercise_routines {
        exercise_routine_name
        id
        fk_video_id
        fk_routine_id
      }
    }
    video_list_aggregate(where: $videoQuery) {
      aggregate {
        count
      }
    }
  }
`;

//get video by id
export const VIDEOBYID = gql`
  query videoById($id: uuid!) {
    video_list_by_pk(id: $id) {
      created_at
      duration_in_minute
      description
      description_mm
      pending
      fk_user_subscription_level_id
      id
      promotion
      thumbnail_image_url
      updated_at
      disable
      video_package_name
      video_url_a
      video_url_b
      video_id
      user_subscription_level {
        subscription_type
        id
      }
      anatomy_image_videos {
        thumbnail_image_url
        id
      }
      video_lists_muscle_types {
        muscle_type {
          name
        }
      }
      experience_level {
        name
        id
      }
      exercise_type {
        exercise_type_name
        id
      }
    }
  }
`;

//Delete Video
export const DELETE_VIDEOS_PK = gql`
  mutation MyMutation($id: uuid!) {
    delete_video_list_by_pk(id: $id) {
      id
    }
  }
`;

// Create video
export const CREATE_VIDEOS = gql`
  mutation MyMutation(
    $video_package_name: String!
    $duration_in_minute: Int!
    $video_url_a: String
    $video_url_b: String
    $thumbnail_image_url: String!
    $promotion: Boolean!
    $description: String!
    $description_mm: String!
    $fk_exercise_type_name: String!
    $fk_experience_level_id: uuid!
    $fk_user_subscription_level_id: Int!
  ) {
    insert_video_list_one(
      object: {
        duration_in_minute: $duration_in_minute
        promotion: $promotion
        video_package_name: $video_package_name
        video_url_a: $video_url_a
        video_url_b: $video_url_b
        thumbnail_image_url: $thumbnail_image_url
        description: $description
        description_mm: $description_mm
        fk_exercise_type_name: $fk_exercise_type_name
        fk_experience_level_id: $fk_experience_level_id
        fk_user_subscription_level_id: $fk_user_subscription_level_id
      }
    ) {
      video_id
      thumbnail_image_url
      id
    }
  }
`;

export const CREATE_VIDEO_MUSCLE_TYPE_LIST = gql`
  mutation MyMutation($fk_muscle_type_id: uuid!, $fk_video_list_id: uuid!) {
    insert_video_lists_muscle_types_one(
      object: {
        fk_muscle_type_id: $fk_muscle_type_id
        fk_video_list_id: $fk_video_list_id
      }
    ) {
      fk_muscle_type_id
      fk_video_list_id
      id
    }
  }
`;
//get video sub type list for videoId
export const GET_SUB_TYPE_LIST = gql`
  query MyQuery($fk_video_list_id: uuid!) {
    video_lists_muscle_types(
      where: { fk_video_list_id: { _eq: $fk_video_list_id } }
    ) {
      muscle_type {
        name
        id
      }
      id
      fk_video_list_id
      fk_muscle_type_id
    }
  }
`;
//get video muscle types
export const GET_VIDEO_MUSCLE_TYPES = gql`
  query MyQuery($fk_video_list_id: uuid!) {
    video_lists_muscle_types(
      where: { fk_video_list_id: { _eq: $fk_video_list_id } }
    ) {
      fk_video_list_id
      fk_muscle_type_id
      id
      muscle_type {
        name
      }
    }
  }
`;
//delete video sub type for update
export const DELETE_MUSCLE_TYPE_LIST = gql`
  mutation aa($fk_video_list_id: uuid!) {
    delete_video_lists_muscle_types(
      where: { fk_video_list_id: { _eq: $fk_video_list_id } }
    ) {
      returning {
        id
        fk_video_list_id
        fk_muscle_type_id
      }
    }
  }
`;

//delete exercise routine for update
export const DELETE_EXE_ROUTINE = gql`
  mutation MyMutation($fk_video_list_id: uuid!) {
    delete_exercise_routine_video(
      where: { fk_video_list_id: { _eq: $fk_video_list_id } }
    ) {
      returning {
        fk_exercise_routine_id
        fk_video_list_id
        id
      }
    }
  }
`;

//Update video
export const UPDATE_VIDEOS = gql`
  mutation MyMutation(
    $id: uuid!
    $video_package_name: String!
    $duration_in_minute: Int!
    $video_url_a: String
    $video_url_b: String
    $thumbnail_image_url: String!
    $promotion: Boolean!
    $description: String!
    $description_mm: String!
    $fk_exercise_type_name: String!
    $fk_experience_level_id: uuid!
    $fk_user_subscription_level_id: Int!
  ) {
    update_video_list_by_pk(
      pk_columns: { id: $id }
      _set: {
        duration_in_minute: $duration_in_minute
        promotion: $promotion
        video_package_name: $video_package_name
        video_url_a: $video_url_a
        video_url_b: $video_url_b
        thumbnail_image_url: $thumbnail_image_url
        description: $description
        description_mm: $description_mm
        fk_exercise_type_name: $fk_exercise_type_name
        fk_experience_level_id: $fk_experience_level_id
        fk_user_subscription_level_id: $fk_user_subscription_level_id
      }
    ) {
      video_id
      thumbnail_image_url
      id
    }
  }
`;

export const SUB_TYPE = gql`
  query VIDEO_SUB_TYPE($sub_type: String) {
    video_sub_type(where: { _and: { sub_type_name: { _eq: $sub_type } } }) {
      id
    }
  }
`;

//update enable disable
export const UPDATE_ENABLE = gql`
  mutation MyMutation($id: uuid!, $disable: Boolean!) {
    update_video_list_by_pk(
      pk_columns: { id: $id }
      _set: { disable: $disable }
    ) {
      disable
      id
    }
  }
`;

export const PACKAGE_TYPE = gql`
  query PackgeType {
    user_subscription_level(
      where: {
        _or: [{ id: { _eq: 2 } }, { id: { _eq: 3 } }, { id: { _eq: 1 } }]
      }
    ) {
      id
      subscription_type
    }
  }
`;
//pending status
export const PENDING = gql`
  mutation MyMutation($id: uuid!, $pending: Boolean!) {
    update_video_list(
      where: { id: { _eq: $id } }
      _set: { pending: $pending }
    ) {
      returning {
        id
        pending
      }
    }
  }
`;

//exercise routine
export const EXERCISE_ROUTINE = gql`
  query MyQuery {
    exercise_routine {
      exercise_routine_name
      id
    }
  }
`;

//get anatomy images
export const ADD_ANATOMYIMAGES = gql`
  mutation MyMutation($fk_video_list_id: uuid!, $thumbnail_image_url: String!) {
    insert_anatomy_image_video_one(
      object: {
        fk_video_list_id: $fk_video_list_id
        thumbnail_image_url: $thumbnail_image_url
      }
    ) {
      id
      thumbnail_image_url
      fk_video_list_id
    }
  }
`;

//delete anatomy image video
export const DELETE_ANATOMYIMAGE = gql`
  mutation MyMutation($thumbnail_image_url: String!, $id: uuid!) {
    delete_anatomy_image_video_by_pk(id: $id) {
      id
      thumbnail_image_url
    }
    deleteImage(imageName: $thumbnail_image_url) {
      error
      message
    }
  }
`;
